.first-slide {
    z-index: 3;
    white-space: nowrap;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 17px;
    border-width: 0px;
    margin: 0px;
    padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 11px; min-height: 0px; min-width: 0px; max-height: none; max-width: none; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform-origin: 50% 50% 0px;
}

.button-first-slide {
    transition: none 0s ease 0s; text-align: inherit; line-height: 33px; border-width: 2px;
    margin: 0px; padding: 0px 21px; letter-spacing: 0px; font-weight: 700; font-size: 13px;
}

.span-button-first-slide {
    transition: none 0s ease 0s; text-align: inherit; line-height: 33px;
    border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 700; font-size: 13px;
}

.icon-first-slide {
    transition: none 0s ease 0s; text-align: inherit;
    line-height: 13px; border-width: 0px; margin: 0px 0px 0px 4px;
    padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 13px;
}

.loop-wrap-first-slide {
    position:absolute;display:block;
}

.parallax-wrap-first-slide {
    position: absolute; display: block; visibility: visible; left: 781px; top: 377px; z-index: 3;
}

.mask-wrap-first-slide {
    position: absolute; display: block; overflow: visible;
}

.citation-home {
    background-image: url("../images/parallax/3.jpg");
    padding: 100px 0px;
    background-position: 0px 300px;
}