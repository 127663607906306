.slide-one {
    width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;
}

.slide-two {
    width: 100%; float: left; margin-right: -100%; position: relative; opacity: 1; display: block; z-index: 2;
}

.slide-three {
    width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;
}

.slide-four {
    width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;
}

/** Slideshow **/

.slide-container {
    width: 100%;
    margin: auto;
}

h3 {
    text-align: center;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: initial;
    height: 400px;
}

.each-fade {
    display: flex;
}

.each-fade .image-container {
    width: 75%;
    overflow: hidden;

}

.each-fade .image-container img {
    width: 100%;
}

.each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
}

.position {
    display: grid;
    grid-template-columns: repeat(12 ,1fr);

    grid-auto-rows: 75px;
    height: 600px;
}

.good{
    grid-column: 2/12;
    grid-row: 0/5;
    margin:auto;
}

/** ! Slideshow ! **/
